import moment from "moment"

export const isBrowser = () => typeof window !== "undefined"

export const formatDate = date => {
  return moment(date).format("DD/MM/yyyy HH:mm")
}
export const formatDateUrl = date => {
  return moment(date).format("DD-MM-yyyy")
}
export const navigateNoRefresh = route => {
  if (isBrowser() && window.history && window.history.pushState) {
    var newurl = window.location.protocol + "//" + window.location.host + route
    window.history.pushState({ path: newurl }, "", newurl)
  }
}
